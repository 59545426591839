const navs = [
   
    {
        link: '/service',
        name: 'Об услуге'
    },
    {
        link: '/partners',
        name: 'Партнеры'
    },
    {
        link: '/garanty',
        name: 'Наша гарантия'
    },
    {
        link: '/contacts',
        name: 'Контакты'
    },
    {
        link: '/faq',
        name: 'FAQ'
    },
    {
        link: '/conditions',
        name: 'Правила'
    }

]

export default navs