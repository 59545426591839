import React from "react";
import Block from "../../components/Block/Block";
import client from "../../content/client";

export default function Conditions() {
  return (
    <div className="conditions">
      <div className="container">
        <Block title="Оферта" classes="text_center">
          <h3 className="text_center">ПРАВИЛА ООО «РосГарантСервис»</h3>
          <p className="ph">
            Настоящие Правила являются официальным предложением (публичной
            офертой) Общества с ограниченной ответственностью «ООО
            «РосГарантСервис»» (ООО «РГС»), в адрес любого физического или
            юридического лица, владельца транспортного средства, обладающего
            дееспособностью и необходимыми полномочиями, заключить договор с ООО
            «РосГарантСервис» на условиях установленных Правилами ООО
            «РосГарантСервис» и действующим законодательством Российской
            Федерации, и содержит все существенные условия договора и порядок
            оказания услуг.
          </p>
          <div className="doc_section">
            <h4 className="doc_title text_center">I. Основные положения</h4>
            <p>
              Для целей, предусмотренных настоящими Правилами, используются
              следующие определения и понятия:
            </p>
            <p>
              <b>Компания</b> - Общество с ограниченной ответственностью «ООО
              «РосГарантСервис»» (ООО «РГС»)
            </p>
            <p>
              <b>Клиент</b> - физическое или юридическое лицо, являющееся
              собственником транспортного средства, на чье имя и фамилию
              оформлен «Паспорт Транспортного Средства» (ПТС)
            </p>
            <p>
              <b>Транспортное средство</b>, далее по тексту «ТС» - легковой
              автомобиль, полной массой до 3500 кг, не старше 2000 года выпуска,
              с пробегом по одометру, на момент смены собственника, не более 200
              000 километров.
            </p>
            <p>
              <b>ПТС</b> - паспорт транспортного средства
            </p>
            <p>
              <b>Договор</b> – соглашение между Клиентом и Компанией,
              заключенное на условиях, предусмотренных настоящими Правилами. В
              рамках договора, Компания принимает на себя обязательства по
              техническому обслуживанию ТС Клиента, на условиях, установленных
              настоящими Правилами, и в соответствии с выбранной Клиентом
              Программой.
            </p>
            <p>
              <b>Карта-Сертификат</b>, далее по тексту <b>«Сертификат»</b> -
              пластиковая карта, физический носитель Сертификата продлённой
              гарантии выпускаемого Компанией. Сертификат удостоверяет право
              Клиента на техническое обслуживание ТС в соответствии с выбранной
              Программой и на условиях, установленных настоящими Правилами. Срок
              действия Сертификата определяется сроком действия выбранной
              Клиентом Программы. Все права на Сертификат принадлежат Компании.
            </p>
            <p>
              <b>Акт осмотра ТС</b>, далее по тексту «Акт осмотра» - документ,
              оформленный Компанией по результатам проведенного осмотра
              технического состояния ТС Клиента, подтверждающий право Клиента на
              получение услуг в соответствии с выбранной Программой и на
              условиях, установленных настоящими Правилами. Форма Акта осмотра
              приведена в Приложении №1 к настоящим Правилам.
            </p>
            <p>
              <b>Программа технического обслуживания</b>, далее по тексту
              «Программа» - объем и условия работ по техническому обслуживанию
              ТС, оказываемых Компанией Клиенту, на условиях, установленных
              настоящими Правилами. Сведения о Программах приведены в Приложении
              №2 к настоящим Правилам.
            </p>
            <p>
              <b>Программа оказания услуг помощи на дороге</b> - объем и условия
              оказания услуг, оказываемых Компанией Клиенту, на условиях,
              установленных в Правилах (Приложение 4)
            </p>
            <p>
              <b>Агент, Партнер Компании</b> - физическое или юридическое лицо,
              официально осуществляющее продажу Сертификатов Компании. Список
              Агентов и Партнеров опубликован на сайте Компании в разделе
              «Партнеры».
            </p>
            <p>
              <b>Лимит ответственности Компании</b> - сумма, в пределах которой,
              Компания производит работы по ремонту ТС на СТО, включая стоимость
              запасных частей, на условиях, предусмотренных настоящими
              Правилами.
            </p>
            <p>
              <b>Условия выполнения работ</b> - документ, определяющий порядок
              выполнения работ по Сертификату и являющийся неотъемлемым
              приложением к настоящим Правилам. (Приложение № 3 к настоящим
              Правилам.)
            </p>
            <p>
              <b>СТО</b> - станции технического обслуживания, определенные
              (авторизованные) Компанией для проведения диагностики
              неисправности, технического обслуживания и ремонта ТС Клиента.
              Полный список СТО опубликован на сайте Компании в разделе
              "Контакты".
            </p>
            <p>
              <b>ТО</b> - плановое (регламентное) техническое обслуживание ТС
              Клиента, определяемое Компанией.
            </p>
          </div>

          <div className="doc_section">
            <h4 className="doc_title text_center">II. Предмет договора</h4>
            <p>
              Предметом настоящей оферты является предложение заключить договор
              на оказание Компанией Клиенту по его требованию (запросу) услуг по
              техническому обслуживанию на СТО, а также помощи на дороге в
              объемах и порядке, установленными настоящими Правилами.
            </p>
          </div>
          <div className="doc_section">
            <h4 className="doc_title text_center">
              III. Порядок заключения договора
            </h4>
            <p>Для заключения договора с Компанией, Клиенту необходимо:</p>
            <ol className="doc_list">
              <li className="doc_list_elem">
                Ознакомиться с настоящими Правилами на сайте Компании:
                <a href="https://росгарантсервис.рф/">росгарантсервис.рф</a>
                <ol className="doc_list">
                  <li className="doc_list_elem">Выбрать Программу</li>
                  <li className="doc_list_elem">
                    Оплатить стоимость Сертификата, согласно выбранной
                    Программе, в соответствии с разделом VII настоящих Правил.
                  </li>
                  <li className="doc_list_elem">
                    Предоставить ТС на указанную Компанией авторизованную СТО,
                    для осмотра и подписания Акта осмотра сотрудником Компании,
                    в срок не позднее 10 (десяти) дней с момента оформления
                    Сертификата.
                  </li>
                  <li className="doc_list_elem">
                    Во время прохождения осмотра ТС, предоставить сотруднику
                    Компании ПТС с отметкой о смене собственника и документ
                    удостоверяющий личность.
                  </li>
                </ol>
              </li>
              <li className="doc_list_elem">
                Если в период действия Сертификата произошла смена собственника
                ТС, для того чтобы продолжить обслуживание по Программе, новый
                владелец ТС обязан:
                <ol className="doc_list">
                  <li className="doc_list_elem">
                    В течение 5 (пяти) дней с момента смены собственника ТС,
                    зарегистрировать (активировать) Сертификат, позвонив по
                    телефону Службы поддержки клиентов:
                    <a href={client.phone.link}>{client.phone.tel}</a>,
                    круглосуточно
                  </li>
                  <li className="doc_list_elem">
                    Предоставить ТС на указанную Компанией авторизованную СТО,
                    для осмотра и подписания Акта осмотра сотрудником Компании в
                    срок не позднее 10 (десяти) дней с момента регистрации
                    (активации) Сертификата.
                  </li>
                  <li className="doc_list_elem">
                    Во время прохождения осмотра ТС, предоставить сотруднику
                    Компании Сертификат, ПТС с отметкой о смене собственника и
                    документ удостоверяющий личность.
                  </li>
                </ol>
              </li>
              <li className="doc_list_elem">
                На нового владельца ТС возлагаются все права и обязанности,
                предусмотренные настоящими Правилами до окончания действия
                Программы, выбранной первым владельцем ТС.
              </li>
              <li className="doc_list_elem">
                В случае если Клиент не предоставит свое ТС, для прохождения
                осмотра в указанный в настоящем абзаце срок, то осмотр ТС будет
                производиться на возмездной для Клиента основе. В этом случае,
                стоимость осмотра и подписания Акта осмотра составит 3000 (три
                тысячи) рублей, вносимые Клиентом в кассу или на расчетный счет
                СТО.
              </li>
            </ol>
          </div>
          <div className="doc_section">
            <h4 className="doc_title text_center">
              IV. Права и обязанности Сторон
            </h4>
            <ol className="doc_list">
              <li className="doc_list_elem">
                Права и обязанности Компании:
                <ul>
                  <li className="doc_list_elem">
                    Провести технический осмотр ТС в месте и в сроки,
                    согласованные с владельцем ТС и выдать владельцу Заказ-наряд
                    на работы и копию Акта Осмотра.
                  </li>
                  <li className="doc_list_elem">
                    В случае поломки узла или деталей ТС предусмотренных
                    Программой, организовать выполнение работ, в соответствии с
                    настоящими Правилами и Условиями выполнения работ по
                    карте-сертификату.
                  </li>
                  <li className="doc_list_elem">
                    Осуществлять контроль качества выполнения работ по
                    Сертификату на СТО, в соответствии с условиями,
                    определенными настоящими Правилами.
                  </li>
                  <li className="doc_list_elem">
                    Принимать письменные претензии от Клиента, в случае, если
                    работы по Сертификату, по мнению Клиента, выполнены СТО
                    некачественно.
                  </li>
                  <li className="doc_list_elem">
                    Осуществлять защитную маркировку деталей ТС, указанных в
                    выбранной Клиентом Программе, если присутствуют подозрения о
                    мошеннических или иных противоправных действиях Клиента
                    и/или третьих лиц по отношению к Компании.
                  </li>
                  <li className="doc_list_elem">
                    Указывать в Акте осмотра рекомендации и определять
                    дополнительные условия к настоящему договору исходя из
                    фактического состояния ТС на момент проведения первичного
                    осмотра.
                  </li>
                </ul>
              </li>
              <li className="doc_list_elem">
                Права и обязанности Клиента:
                <ul>
                  <li className="doc_list_elem">Выбрать Программу.</li>
                  <li className="doc_list_elem">
                    Внести средства в оплату стоимости Сертификата согласно
                    выбранной Программе.
                  </li>
                  <li className="doc_list_elem">
                    Ознакомиться с условиями настоящих Правил.
                  </li>
                  <li className="doc_list_elem">
                    В месте определенным Компанией, в согласованные с Компанией
                    сроки, предоставить ТС для прохождения первичного осмотра и
                    подписания сотрудником Компании акта осмотра ТС.
                  </li>
                  <li className="doc_list_elem">
                    Информировать Компанию о смене собственника ТС.
                  </li>
                  <li className="doc_list_elem">
                    Сохранять, и по требованию Компании предоставлять
                    Сертификат, Акт осмотра и другую, полученную от СТО
                    документацию, связанную с ремонтом и регулярным
                    обслуживанием ТС.
                  </li>
                  <li className="doc_list_elem">
                    Обращаться к Компании за разъяснениями по поводу условий
                    настоящего Договора и сложившихся отношений, сроков и
                    технических особенностей выполнения работ по Сертификату.
                  </li>
                </ul>
              </li>
            </ol>
          </div>

          <div className="doc_section">
            <h4 className="doc_title text_center">V. Срок действия договора</h4>
            <ol className="doc_list">
              <li className="doc_list_elem">
                Настоящий договор вступает в силу с момента приобретения
                Клиентом Сертификата
              </li>
              <li className="doc_list_elem">
                Настоящий договор прекращает свое действие по факту наступления
                одного из следующих событий:
                <ul>
                  <li className="doc_list_elem">
                    По истечении срока действия выбранной Клиентом Программы.
                  </li>
                  <li className="doc_list_elem">
                    При превышении максимально допустимого показателя пробега
                    ТС, указанного в выбранной Клиентом Программе. Отсчет срока
                    действия и пробега ТС осуществляется от сведений, указанных
                    в акте осмотра ТС
                  </li>
                  <li className="doc_list_elem">
                    Если общая стоимость всех выполненных и оплаченных Компанией
                    работ по Сертификату, в совокупности, достигла
                    установленного лимита ответственности Компании, указанного в
                    выбранной Клиентом Программе.
                  </li>
                </ul>
              </li>
            </ol>
          </div>

          <div className="doc_section">
            <h4 className="doc_title text_center">VI. Порядок расчетов по Договору</h4>
            <ol className="doc_list">
              <li className="doc_list_elem">
                Клиент вносит сумму равную стоимости Сертификата исходя из
                выбранной Клиентом Программы, единовременно, в кассу или на
                расчетный счет Компании, Агента или Партнера Компании.
              </li>
              <li className="doc_list_elem">
                Полученная от Клиента сумма является абонентским платежом и
                засчитывается Компанией в счет оплаты Клиентом стоимости
                Сертификата по факту вступления в действие настоящего Договора,
                в соответствии с пунктом 5 настоящего Раздела
              </li>
              <li className="doc_list_elem">
                . В случае если общая стоимость запчастей и работ, выполненных
                на СТО, за весь период действия Сертификата, превысит лимит
                ответственности Компании, указанный в выбранной Клиентом
                Программе, сумма превышения выплачивается Клиентом в кассу или
                на расчетный счет СТО самостоятельно, за счет собственных
                средств.
              </li>
              <li className="doc_list_elem">
                Стоимость запасных частей и работ, выполненных на СТО
                превышающая лимит ответственности Компании на одно обращение,
                выплачивается Клиентом в кассу или на расчетный счет СТО
                самостоятельно, за счет собственных средств.
              </li>
              <li className="doc_list_elem">
                Денежные средства, полученные Компанией от Клиента в счет оплаты
                услуг по Договору, являются абонентским платежом, который
                учитывается в следующем порядке: абонентский платеж за первый
                месяц действия Договора (независимо от количества дней действия
                Программы в соответствующем месяце), включающий расходы Компании
                по исполнению Договора, определяется по формуле: А1 = С – 5000
                руб., где А1 - абонентский платеж за первый месяц действия
                Договора (независимо от количества дней действия Программы в
                соответствующем месяце), С – соответствующая стоимость услуг по
                Договору согласно выбранной Клиентом Программе. Абонентский
                платеж за каждый последующий месяц срока действия выбранной
                Клиентом Программы (независимо от количества дней действия
                Программы в соответствующем месяце) определяется по формуле: Аn
                = 5000 руб./(К–1), где An - абонентский платеж за
                соответствующий последующий месяц срока действия выбранной
                Клиентом Программы (независимо от количества дней действия
                Программы в соответствующем месяце), К – количество месяцев в
                сроке действия выбранной Клиентом Программы.
              </li>
            </ol>
          </div>

          <div className="doc_section">
            <h4 className="doc_title text_center">
              VII. Заключительные положения
            </h4>
            <ol className="doc_list">
              <li className="doc_list_elem">
                В Акте осмотра могут быть указаны дополнительные условия к
                настоящему договору определенные Компанией, исходя из
                фактического технического состояния ТС Клиента на момент
                проведения первичного осмотра. В случае выявления в ходе
                первичного осмотра неисправных узлов и агрегатов ТС, в
                перечисленных в выбранной Клиентом Программе, Компания вправе
                дать Клиенту рекомендации по устранению данных неисправностей, о
                чем указывается в Акте осмотра. В случае неисполнения Клиентом
                обязанности по устранению указанных в Акте осмотра
                неисправностей, в указанные Компанией и обозначенные в Акте
                осмотра, сроки, Компания имеет право отказать Клиенту в ремонте
                и техническом обслуживании данных узлов и агрегатов ТС Клиента,
                в случае их выхода из строя впоследствии. Обязанность Компании
                по ремонту и техническому обслуживанию данных узлов и агрегатов
                возникает после выполнения Клиентом рекомендаций Компании по
                устранению неисправностей и предоставления Клиентом ТС на
                повторный осмотр на авторизованное Компанией СТО. Факт
                устранения или не устранения Клиентом неисправностей,
                фиксируется в новом Акте осмотра.
              </li>
              <li className="doc_list_elem">
                При отказе Клиента от прохождения первичного осмотра (и) или
                процедуры ТО1, Компания вправе отказать Клиенту в подключении к
                Программе до тех пор, пока ТС Клиента не пройдет первичный
                осмотр и не выполнит требование Компании по прохождению ТО1.
              </li>
              <li className="doc_list_elem">
                Программа Компании для ТС находящегося на гарантии
                завода-изготовителя, может быть предоставлена Клиенту, при
                условии приобретения Сертификата в любой момент времени начиная
                с даты покупки нового ТС и до истечения гарантийных обязательств
                производителя. По истечении заводской гарантии, клиент обязуется
                самостоятельно зарегистрировать (активировать) Сертификат в
                течение 5 (пяти) дней, позвонив по телефону Службы поддержки
                клиентов: 8 (800) 550-22-38, круглосуточно. А также предоставить
                ТС на указанную Компанией авторизованную СТО, для осмотра и
                подписания Акта осмотра сотрудником Компании, в срок не позднее
                10 (десяти) дней с момента регистрации (активации) Сертификата.
              </li>
              <li className="doc_list_elem">
                Если ТС Клиента, получившего Сертификат Компании, находится на
                заводской гарантии, то прохождение первичного осмотра ТС в
                Компании, не требуется.
              </li>
              <li className="doc_list_elem">
                Программа вступает в силу:
                <ul>
                  <li className="doc_list_elem">
                    при выполнении Клиентом условий, указанных в пункте III.1.
                    настоящих Правил.
                  </li>
                  <li className="doc_list_elem">
                    через 14 дней после подписания Акта осмотра, но не ранее
                    достижения ТС пробега в 1000 (одна тысяча) километров по
                    одометру ТС, от сведений о пробеге, указанных в Акте осмотра
                    ТС, если иное не оговорено в Акте осмотра ТС.
                  </li>
                </ul>
              </li>
              <li className="doc_list_elem">
                В случае одностороннего отказа Клиента от исполнения Договора
                сумма, подлежащая возврату Клиенту, определяется (с учетом
                положений пункта 5 раздела VII Договора) по формуле: В = С – (А1
                + Аn*N), где В - сумма, подлежащая возврату Клиенту, С -
                соответствующая стоимость услуг по Сертификату согласно
                выбранной Клиентом Программе, А1 - абонентский платеж за первый
                месяц действия Сертификата (независимо от количества дней
                действия Программы в соответствующем месяце), An - абонентский
                платеж за соответствующий последующий месяц срока действия
                выбранной Клиентом Программы (независимо от количества дней
                действия Программы в соответствующем месяце), N – количество
                соответствующих последующих месяцев действия Программы, включая
                месяц, в котором Клиентом было подано заявление об одностороннем
                отказе от исполнения Договора (независимо от количества дней
                действия Программы в соответствующем месяце).
              </li>
              <li className="doc_list_elem">
                Клиент и Компания принимают необходимые меры для урегулирования
                возникших разногласий по настоящему Договору путем переговоров
              </li>
              <li className="doc_list_elem">
                В случае, если в течение 30 (тридцати) дней Клиент и Компания не
                достигнут согласия по поводу претензий, спор подлежит разрешению
                в судебном порядке в соответствии с действующим
                законодательством Российской Федерации
              </li>
              <li className="doc_list_elem">
                Согласно ст. 32 Гражданско-процессуального кодекса РФ стороны
                установили договорную подсудность разрешения споров. Возникшие
                между сторонами споры подлежат рассмотрению Невским районным
                судом г. Санкт-Петербурга либо Мировым судьей с/у №90 г.
                Санкт-Петербурга в соответствии с нормами процессуального
                законодательства Российской Федерации.
              </li>
              <li className="doc_list_elem">
                Компания не несет ответственности, за причинение Клиенту
                морального ущерба, упущенной выгоды, простоя, потери дохода и
                других косвенных и коммерческих потерь, убытков и расходов
                Клиента или третьих лиц.
              </li>
              <li className="doc_list_elem">
                В случае, если ТС Клиента прошло предпродажную диагностику на
                СТО дилерского центра, и тому есть документальное подтверждение,
                прохождение первичного осмотра и подписание Акта осмотра в
                Компании не требуется.
              </li>
              <li className="doc_list_elem">
                Компания вправе в одностороннем порядке вносить изменения в
                настоящие Правила, при этом Клиент считается извещенным о
                соответствующих изменениях с момента их опубликования на сайте
                Компании.
              </li>
              <li className="doc_list_elem">
                Заключая настоящий договор, Клиент выражает согласие Компании на
                обработку своих персональных данных, включая их сбор,
                систематизацию, накопление, хранение, уточнение (обновление,
                изменение), использование, распространение (в том числе
                передачу), обезличивание, блокирование, уничтожение (далее –
                обработка персональных данных) для целей заключения с Клиентом
                или в его интересах договоров, их исполнения (в том числе
                информирования об этом с помощью средств связи), уступки прав по
                данным договорам третьим лицам или привлечения третьих лиц для
                оказания помощи в получении исполнения по ним и/или для
                заключения в интересах Клиента договоров, а также для передачи
                его персональных данных на хранение третьим лицам.
              </li>
            </ol>
          </div>

          <div className="doc_section">
            <h4 className="doc_title text_center">
              VIII. Существующие приложения к договору
            </h4>
            <p>
              <a href="#add1">Приложение №1</a> - Акт осмотра транспортного
              средства.
            </p>
            <p>
              <a href="#add2">Приложение №2</a> - Программы технического
              обслуживания.
            </p>
            <p>
              <a href="#add3">Приложение №3</a> - Условия выполнения работ по
              Сертификату.
            </p>
            <p>
              <a href="#add4">Приложение №4</a> - Условия и порядок оказания
              услуг помощи на дороге
            </p>
            <p>
              Все приложения являются неотъемлемой частью настоящего договора
              Договора-оферты.
            </p>
          </div>
        </Block>

        <Block>
          <div className="doc_side">
            <p className="doc_side_text">
              Приложение №2 <br />к Правилам ООО «РГС»
            </p>
          </div>
          <h4 className="doc_title">
            Программа технического обслуживания «Сертификат»
          </h4>
          <div className="doc_section">
            <p>
              Распространяется на автомобили с 2000 г.в. и пробегом до 200 000
              (двести тысяч) км.
            </p>
            <p>
              Лимит ответственности Компании в совокупности по всем затратам за
              весь срок действия договора 150 000 (Сто пятьдесят тысяч) рублей.
            </p>
            <p>
              Лимит ответственности на одно обращение для ремонта ДВС – 60 000
              (Шестьдесят тысяч) рублей.
            </p>
            <p>
              Лимит ответственности на одно обращение для ремонта МКПП/АКПП – 40
              000 (Сорок тысяч) рублей.
            </p>
            <p>Зона действия программы: территория Российской Федерации.</p>
            <p>
              Срок действия программы составляет: 12 месяцев или 20 000 км
              пробега
            </p>
            <p>
              Перечень узлов и агрегатов, подлежащих обслуживанию по
              Сертификату:
            </p>
            <ul>
              Двигатель:
              <li className="doc_list_elem">
                <ul>
                  <li className="doc_list_elem">
                    Блок цилиндров: детали цилиндропоршневой группы: поршни,
                    шатуны, поршневые пальцы, компрессионные и маслосъемные
                    кольца, шатунные и коренные вкладыши, коленчатый вал, поддон
                    картера
                  </li>
                  <li className="doc_list_elem">
                    Система смазки: маслоприемник, масляный насос
                  </li>
                </ul>
              </li>
              <li className="doc_list_elem">
                Трансмиссия:
                <ul>
                  <li className="doc_list_elem">
                    Механическая коробка передач: все смазываемые внутренние
                    детали
                  </li>
                  <li className="doc_list_elem">
                    Автоматическая коробка передач: все смазываемые внутренние
                    детали, за исключением гидротрансформатора,
                    электрогидравлического блока управления (мехатроник) и
                    гидравлического блока управления (гидроблок).
                  </li>
                  <li className="doc_list_elem">
                    Роботизированная коробка передач: все смазываемые внутренние
                    детали, за исключением блоков управления
                  </li>
                  <li className="doc_list_elem">
                    Вариатор: все смазываемые внутренние детали, за исключением
                    блоков управления
                  </li>
                </ul>
              </li>
            </ul>
          </div>

          <div className="doc_section">
            <h4 className="doc_title">
              Программа технического обслуживания «Гарантия Плюс»
            </h4>
            <p>
              Распространяется на автомобили с 2010 г.в. и пробегом до 150 000
              (сто пятьдесят тысяч) км.
            </p>
            <p>
              Лимит ответственности Компании в совокупности по всем затратам за
              весь срок действия договора 150 000 (сто пятьдесят тысяч) рублей.
            </p>
            <p>
              Лимит ответственности на одно обращение для ремонта ДВС – 60 000
              (Шестьдесят тысяч) рублей.
            </p>
            <p>
              Лимит ответственности на одно обращение для ремонта МКПП/АКПП – 40
              000 (Сорок тысяч) рублей.
            </p>
            <p>
              Лимит ответственности на одно обращение для ремонта трансмиссии
              (кроме КПП) – 20 000 (Двадцать тысяч) рублей
            </p>
            <p>
              Лимит ответственности на одно обращение для ремонта прочих узлов и
              агрегатов – 10 000 (Десять тысяч) рублей.
            </p>
            <p>Зона действия программы: территория Российской Федерации.</p>
            <p>
              Срок действия программы составляет: 12 месяцев или 20 000 км
              пробега
            </p>
            <p>
              Перечень узлов и агрегатов, подлежащих обслуживанию по
              Сертификату:
            </p>
            <ul>
              <li className="doc_list_elem">
                <ul>
                  Двигатель:
                  <li className="doc_list_elem">
                    Головка блока цилиндров: впускные и выпускные клапаны, седла
                    клапанов, направляющие втулки клапанов, пружины клапанов с
                    составными деталями, распределительный вал, толкатели
                    клапанов, крышка клапанного механизма
                  </li>
                  <li className="doc_list_elem">
                    Блок цилиндров: детали цилиндропоршневой группы: поршни,
                    шатуны, поршневые пальцы, компрессионные и маслосъемные
                    кольца, шатунные и коренные вкладыши, коленчатый вал, поддон
                    картера
                  </li>
                </ul>
              </li>
              <li className="doc_list_elem">
                Система смазки: маслоприемник, масляный насос
                <ul>
                  <li className="doc_list_elem">Впускной коллектор</li>
                  <li className="doc_list_elem">Выпускной коллектор</li>
                </ul>
              </li>
              <li className="doc_list_elem">
                Трансмиссия:
                <ul>
                  <li className="doc_list_elem">
                    Механическая коробка передач: все смазываемые внутренние
                    детали
                  </li>
                  <li className="doc_list_elem">
                    Автоматическая коробка передач: все смазываемые внутренние
                    детали, за исключением гидротрансформатора,
                    электрогидравлического блока управления (мехатроник) и
                    гидравлического блока управления (гидроблок).
                  </li>
                  <li className="doc_list_elem">
                    Роботизированная коробка передач: все смазываемые внутренние
                    детали, за исключением блоков управления
                  </li>
                  <li className="doc_list_elem">
                    Вариатор: все смазываемые внутренние детали, за исключением
                    блоков управления
                  </li>
                  <li className="doc_list_elem">
                    Раздаточная коробка передач: все смазываемые внутренние
                    детали, за исключением цепи и натяжителя
                  </li>
                  <li className="doc_list_elem">
                    Редуктор заднего моста: все смазываемые внутренние детали
                  </li>
                  <li className="doc_list_elem">
                    Редуктор переднего моста: все смазываемые внутренние детали
                  </li>
                </ul>
              </li>
              <li className="doc_list_elem">
                Прочие узлы:
                <ul>
                  <li className="doc_list_elem">Стартер</li>
                  <li className="doc_list_elem">Генератор</li>
                  <li className="doc_list_elem">Компрессор кондиционера</li>
                  <li className="doc_list_elem">Насос гидроусилителя руля</li>
                  <li className="doc_list_elem">
                    Рулевой механизм (за исключением рулевых наконечников,
                    рулевой тяги, рулевой трапеции)
                  </li>
                  <li className="doc_list_elem">
                    Насос жидкостного охлаждения двигателя
                  </li>
                  <li className="doc_list_elem">Радиатор системы охлаждения</li>
                  <li className="doc_list_elem">Радиатор кондиционера</li>
                </ul>
              </li>
              <li className="doc_list_elem">
                Помощь на дороге:
                <ol className="doc_list">
                  <li className="doc_list_elem">
                    Экстренная техническая помощь в случае Неисправности и/или
                    приравненного к ней события
                  </li>
                  <li className="doc_list_elem">
                    Эвакуация Транспортного средства Клиента в случае
                    Неисправности и/или приравненного к ней события
                  </li>
                  <li className="doc_list_elem">
                    Справочно-консультационная помощь
                  </li>
                </ol>
              </li>
            </ul>
          </div>

          <div className="doc_section">
            <h4 className="doc_title">
              Ограничения в предоставлении услуг по программам:
            </h4>
            <ul>
              Программы не предоставляются для транспортных средств:
              <li className="doc_list_elem">
                старше 2000 года выпуска и пробегом, превышающим допустимый
                максимум указанный в программах.
              </li>
              <li className="doc_list_elem">
                российских марок старше 2010 года выпуска.
              </li>
              <li className="doc_list_elem">
                китайского производства старше 2010 года выпуска.
              </li>
              <li className="doc_list_elem">
                оборудованных роторно-поршневыми или электродвигателями.
              </li>
              <li className="doc_list_elem">работающих на сжиженном газе. </li>
              <li className="doc_list_elem">
                имеющих модификацию или изменения, затрагивающие силовую часть
                или трансмиссию.
              </li>
              <li className="doc_list_elem">
                имеющих, на момент проведения первичного осмотра, признаки
                повреждения в узлах и агрегатах, указанных в Программах.
              </li>
              <li className="doc_list_elem">
                с момента покупки ТС Клиентом прошло более 10 (десяти) дней.
              </li>
            </ul>
          </div>
        </Block>

        <Block>
          <div className="doc_side">
            <p className="doc_side_text">
              Приложение №3 <br />к Правилам ООО «РГС»
            </p>
          </div>
          <h4 className="doc_title">
            Условия выполнения работ по карте-сертификату в рамках технического
            обслуживания ТС
          </h4>
          <ol className="doc_list">
            <li className="doc_list_elem">
              Технический регламент обслуживания и эксплуатации ТС
              <ul>
                <li className="doc_list_elem">
                  Обязательным требованием Компании, является соблюдение
                  Клиентом графика планового ТО, установленного Компанией, при
                  прохождении первичного осмотра ТС и подписании Акта осмотра, и
                  в настоящих Правилах. Обязательной, является процедура смена
                  моторного масла и фильтра двигателя ТС, по графику
                  отображенному в данных Правилах (прохождение ТО1 - в течение
                  10 (десяти) рабочих дней с момента покупки ТС, и ТО2 - через
                  10000 (десять тысяч) километров, но не позднее 6 (шести)
                  месяцев с момента покупки ТС) если иное не оговорено в Акте
                  осмотра. ТО2 включает в себя, помимо смены масла и фильтра
                  двигателя, проверку уровня и качества масла в коробке
                  переключения передач (КПП).
                </li>
                <li className="doc_list_elem">
                  Для Клиента является обязательным прохождение планового ТО на
                  одной из авторизованных Компанией СТО, если иное не оговорено
                  в Акте осмотра. Полный список СТО опубликован на сайте
                  Компании в разделе "Контакты". Плановое ТО оплачивается
                  клиентом.
                </li>
                <li className="doc_list_elem">
                  После каждого прохождения Клиентом планового технического
                  обслуживания ТС, Клиент обязан получить от СТО соответствующие
                  заказ-наряды, чеки и иные документы, подтверждающие факт
                  своевременной замены смазочных материалов ТС.
                </li>
                <li className="doc_list_elem">
                  Клиент обязан использовать горюче-смазочные материалы и
                  эксплуатационные жидкости только надлежащего качества,
                  рекомендованные изготовителем ТС, и не использовать
                  дополнительные добавки к топливу, моторному или
                  трансмиссионному маслу, а также средства внутренней очистки
                  двигателя и других узлов ТС, не рекомендованные к
                  использованию изготовителем ТС.
                </li>
                <li className="doc_list_elem">
                  Клиент обязан не использовать ТС (даже единовременно), для
                  целей, противоречащих или не предусмотренных его обычным
                  назначениям - испытания на время, на скорость, на
                  проходимость, а также не использовать ТС в учебных целях, в
                  качестве такси или коммерческого транспорта, если иное не
                  предусмотрено выбранной Программой или не оговорено в Акте
                  осмотра.
                </li>
              </ul>
            </li>
            <li className="doc_list_elem">
              Порядок выполнения работ по Сертификату
              <ul>
                <li className="doc_list_elem">
                  При возникновении признаков поломки или разрушения в узлах и
                  агрегатах ТС, указанных в выбранной Клиентом Программе, Клиент
                  обязан прекратить эксплуатацию транспортного средства до
                  получения от Компании направления на авторизованную СТО.
                  Клиенту необходимо в течение 3 (трех) дней направить в адрес
                  Компании предварительную заявку, посредством телефонного
                  звонка в Службу поддержки клиентов компании, по указанным в
                  Сертификате телефонам:
                  <a href={client.phone.link}>{client.phone.tel}</a>,
                  круглосуточно
                </li>
                <li className="doc_list_elem">
                  Компания обязана в течение 3 (трех) рабочих дней, с момента
                  получения от Клиента предварительной заявки, направить ТС на
                  авторизованную СТО для проведения диагностики, с целью
                  определения обоснованности выполнения работ по Сертификату.
                  Если срок направления ТС на диагностику был нарушен по
                  обстоятельствам, не зависящим от Компании, обязанность
                  Компании считается исполненной в срок.
                </li>
                <li className="doc_list_elem">
                  Компания самостоятельно определяет выбор авторизованной СТО,
                  дату и время для проведения диагностики и работ по
                  Сертификату, с учетом графика работы и свободного времени на
                  СТО, и доводит данную информацию до Клиента.
                </li>
                <li className="doc_list_elem">
                  Клиент обязан пройти диагностику в течение 7 (семи)
                  календарных дней с момента направления (если не согласовано
                  иное). Перед прохождением диагностики Клиент обязан
                  предоставить документы, подтверждающие факт своевременного
                  прохождения Клиентом планового технического обслуживания ТС.
                </li>
                <li className="doc_list_elem">
                  Непрохождение Клиентом диагностики в установленные (или
                  согласованные дополнительно) сроки считается отказом Клиента
                  от предоставления услуг Компании по заявленному признаку
                  поломки (дефекту) и лишает Клиента возможности повторного
                  обращения с этой проблемой.
                </li>
                <li className="doc_list_elem">
                  Клиент обязан самостоятельно и за свой счет осуществить
                  доставку ТС к месту проведения диагностики поломки, если иное
                  не оговорено в выбранной Клиентом Программе.
                </li>
                <li className="doc_list_elem">
                  Компания вправе отказать Клиенту в выполнении работ по
                  Сертификату, в случаях, указанных в разделе 3 настоящих
                  Условий.
                </li>
                <li className="doc_list_elem">
                  В случае отказа Компании от выполнения работ по Сертификату,
                  по обстоятельствам, указанным в разделе 3 настоящих Условий,
                  Клиент обязан оплатить услуги СТО по диагностике
                  самостоятельно, за счет собственных средств.
                </li>
                <li className="doc_list_elem">
                  После получения Компанией от специалистов СТО осуществивших
                  диагностику ТС, результатов, подтверждающих право Клиента на
                  проведение работ по Сертификату, ТС Клиента направляется
                  Компанией на авторизованную СТО для выполнения
                  восстановительных работ.
                </li>
                <li className="doc_list_elem">
                  Перед началом проведения работ по Сертификату, Клиент обязан
                  представить Компании заказ-наряды, чеки или иные документы,
                  подтверждающие факт своевременной замены смазочных жидкостей
                  ТС, в соответствии с графиком плановых ТО, определенным
                  Компанией и указанном в настоящих Правилах.
                </li>
                <li className="doc_list_elem">
                  Компанией покрывается стоимость работ на СТО, запасных частей
                  и расходных материалов, при возникновении поломки или
                  разрушения деталей ТС, указанных в выбранной Клиентом
                  Программе, вследствие проявления скрытых дефектов материалов
                  или сборки. Не подлежат обслуживанию по Сертификату поломки,
                  произошедшие в следствие естественного износа деталей ТС,
                  одним из результатов которого является повышенный расход масла
                  двигателя, износ (выработка) деталей сцепления на механических
                  и роботизированных КПП, а также изношенные уплотнители,
                  прокладки, сальники, пыльники, резиновые и пластиковые детали,
                  цепь грм с натяжителями, ремень грм с натяжителями, цепь
                  раздаточной коробки.
                </li>
                <li className="doc_list_elem">
                  Запчасти, применяемые для проведения работ по Сертификату,
                  могут быть новыми, восстановленными или перемещенными, но с
                  сохранением всех свойств необходимых для дальнейшей корректной
                  и безопасной работы отремонтированного узла или агрегата. ∙
                  Сроки проведения работ по Сертификату не могут превышать 45
                  (сорока пяти) дней с момента определения неисправности. Если
                  срок проведения работ по Сертификату был нарушен по
                  обстоятельствам, не зависящим от Компании, обязанность
                  Компании считается исполненной в срок.
                </li>
                <li className="doc_list_elem">
                  Для целей настоящих Условий, под моментом определения
                  неисправности понимается дата получения Компанией официального
                  заключения от СТО по результатам проведенной диагностики ТС
                  Клиента.
                </li>
                <li className="doc_list_elem">
                  В период действия Программы, Владелец ТС обязан сохранять и,
                  по требованию Компании, предоставлять всю полученную от СТО
                  документацию, связанную с ремонтом и регулярным обслуживанием
                  ТС.
                </li>
              </ul>
            </li>
            <li className="doc_list_elem">
              Обстоятельства, исключающие оказание Клиенту услуг по Сертификату
              <br />
              Компания оставляет за собой право отказать Клиенту в оказании
              услуг, предусмотренных Сертификатом если:
              <ul>
                <li className="doc_list_elem">
                  на момент заключения договора и подписания Акта осмотра, ТС
                  находится в неисправном состоянии и имеет явные признаки
                  повреждения или некорректной работы в узлах, перечисленных в
                  Программе.
                </li>
                <li className="doc_list_elem">
                  произошла поломка или разрушение деталей ТС не перечисленных в
                  выбранной Клиентом Программе.
                </li>
                <li className="doc_list_elem">
                  естественный износ деталей, узлов и агрегатов, покрываемых
                  Программой.
                </li>
                <li className="doc_list_elem">
                  поломка или разрушение узлов или агрегатов ТС произошли из-за
                  несоблюдения Клиентом рекомендованных изготовителем ТС норм,
                  регламентирующих количество смазочных материалов и
                  эксплуатационных жидкостей в ТС, а также в следствие
                  разрушения или несвоевременной замены расходных материалов или
                  деталей, подверженных естественному износу (прокладки,
                  резиновые уплотнения, сальники, пыльники, цепь грм с
                  натяжителями, ремень грм с натяжителями, цепь раздаточной
                  коробки, детали механизма сцепления коробки переключения
                  передач и т.п.).
                </li>
                <li className="doc_list_elem">
                  произошла поломка электрооборудования, элементов кузова,
                  салона или деталей подвески ТС.
                </li>
                <li className="doc_list_elem">
                  нарушена установленная Компанией защитная маркировка агрегатов
                  ТС Клиента.
                </li>
                <li className="doc_list_elem">
                  поломка или разрушение узлов, или агрегатов ТС произошли в
                  перемещенных агрегатах, изначально не установленных
                  изготовителем на данное ТС.
                </li>
                <li className="doc_list_elem">
                  Клиент не смог предоставить Компании свой Сертификат, копию
                  заполненного сотрудником Компании Акта первичного осмотра ТС,
                  с отметками СТО, отражающими своевременность пройденных
                  обязательных ТО, а также заказ-наряды, чеки или иные документы
                  с отметками СТО, подтверждающие факт своевременного
                  прохождения Клиентом планового технического обслуживания ТС.
                </li>
                <li className="doc_list_elem">
                  поломка или разрушение узлов, или агрегатов ТС возникли
                  вследствие нарушения Клиентом графика прохождения
                  обязательного ТО, а также, условий эксплуатации ТС,
                  определенных заводом-изготовителем ТС.
                </li>
                <li className="doc_list_elem">
                  поломка или разрушение узлов, или агрегатов ТС произошли
                  вследствие воздействия на ТС химических выбросов, загрязнений
                  воздуха, дорожной соли, химически активных веществ или добавок
                  к горюче-смазочным материалам; действий природных сил или ДТП,
                  а также применения горюче-смазочных материалов ненадлежащего
                  качества.
                </li>
                <li className="doc_list_elem">
                  поломка или разрушение узлов, или агрегатов ТС произошли из-за
                  применения деталей, на которые не существует первоначальной
                  гарантии завода-изготовителя.
                </li>
                <li className="doc_list_elem">
                  поломка или разрушение узлов, или агрегатов ТС произошли из-за
                  произведенной модификации ТС не предусмотренной изготовителем
                  ТС.
                </li>
                <li className="doc_list_elem">
                  поломка или разрушение узлов, или агрегатов ТС произошли
                  вследствие поломки электрооборудования ТС.
                </li>
                <li className="doc_list_elem">
                  Клиент не следовал рекомендациям и дополнительным условиям
                  указанным Компанией в Акте осмотра.
                </li>
                <li className="doc_list_elem">
                  Клиент использовал ТС (даже единовременно), для целей,
                  противоречащих или не предусмотренных его обычным назначениям
                  - испытания на время, на скорость, на проходимость.
                </li>
                <li className="doc_list_elem">
                  Клиент использовал ТС в учебных целях, в качестве такси или
                  коммерческого транспорта, если данное не было предусмотрено
                  выбранной Клиентом Программой или не было оговорено в Акте
                  осмотра.
                </li>
                <li className="doc_list_elem">
                  Клиент нарушил график или отказался от прохождения планового
                  ТО, указанного в настоящих Правилах.
                </li>
                <li className="doc_list_elem">
                  ТС Клиента не прошло 1000 (одной тысячи) км от сведений о
                  пробеге указанных в Акте осмотра.
                </li>
                <li className="doc_list_elem">
                  учетные данные, указанные Клиентом, не соответствуют
                  действительной информации.
                </li>
                <li className="doc_list_elem">
                  Клиентом нарушены условия настоящего договора.
                </li>
                <li className="doc_list_elem">
                  в течение срока действия выбранной Программы, со счетчиком
                  пройденного пути (одометром) ТС Клиента, были произведены
                  какие-либо действия.
                </li>
                <li className="doc_list_elem">
                  присутствуют подозрения о мошеннических или иных
                  противоправных действиях Клиента и/или третьих лиц по
                  отношению к Компании.
                </li>
              </ul>
            </li>
          </ol>
        </Block>

        <Block>
          <div className="doc_side">
            <p className="doc_side_text">
              Приложение №4 <br />к Правилам ООО «РГС»
            </p>
          </div>

          <h4 className="doc_title">
            Условия выполнения работ по карте-сертификату в рамках технического
            обслуживания ТС
          </h4>

          <div className="doc_section">
            <ol className="doc_list">
              <li className="doc_list_elem">
                ОКАЗЫВАЕМЫЕ УСЛУГИ
                <ol className="doc_list">
                  <li className="doc_list_elem">
                    В рамках оказания услуг помощи на дороге по Сертификату,
                    Компания, оказывает по заказу Клиента круглосуточно, без
                    ограничений по количеству следующие услуги в отношении
                    Транспортного средства, указанного при покупке Сертификата
                    (далее по тексту – Транспортное средство) и предоставляет
                    Клиенту следующую информацию:
                    <ol className="doc_list">
                      <li className="doc_list_elem">
                        Экстренная техническая помощь в случае Неисправности
                        и/или приравненного к ней в соответствии с настоящими
                        Правилами события (пункт 1.3.1 настоящих Правил);
                      </li>
                      <li className="doc_list_elem">
                        Эвакуация Транспортного средства Клиента в случае
                        Неисправности и/или приравненного к ней в соответствии с
                        настоящими Правилами события (пункт 1.3.2 настоящих
                        Правил);
                      </li>
                      <li className="doc_list_elem">
                        Справочно-консультационная помощь (пункт 1.3.3 настоящих
                        Правил);
                      </li>
                    </ol>
                  </li>
                  <li className="doc_list_elem">
                    Любые работы и услуги, связанные с выполнением мероприятий,
                    указанных в пункте
                  </li>
                  <li className="doc_list_elem">
                    настоящих Правил, кроме справочно-консультационных услуг,
                    выполняются при условии нахождения Транспортного средства в
                    момент Неисправности на Автомобильной дороге в пределах
                    границ, указанных в Разделе 3 настоящих Правил, и не более
                    чем один раз по каждому событию, послужившему причиной
                    обращения Клиента в Компанию. При нахождении Транспортного
                    средства вне территории, указанной в Разделе 3 настоящих
                    Правил, услуги оказываются Клиенту в порядке, указанном в
                    пункте 2.5 настоящих Правил.
                  </li>
                  <li className="doc_list_elem">
                    Услуги помощи на дороге, указанные в пункте 1.1 настоящих
                    Правил включают в себя следующие виды работ и услуг:
                    <ol className="doc_list">
                      <li className="doc_list_elem">
                        В рамках экстренной технической помощи не менее чем один
                        из следующих видов работ и услуг:
                        <ol className="doc_list">
                          <li className="doc_list_elem">
                            {" "}
                            Проведение мероприятий по запуску двигателя
                            Транспортного средства, в том числе не менее чем от
                            одного внешнего источника;
                          </li>
                          <li className="doc_list_elem">
                            Экстренный ремонт систем электрооборудования
                            Транспортного средства;
                            <br />
                            Экстренный ремонт систем электрооборудования
                            транспортного средства включает в себя частичное
                            обслуживание штатной аккумуляторной батареи
                            транспортного средства без обязательной зарядки
                            и/или обязательного вскрытия штатной аккумуляторной
                            батареи транспортного средства; замену штатных
                            предохранителей транспортного средства и/или штатных
                            реле транспортного средства, при наличии технической
                            возможности; проведение мероприятий по отключению
                            установленной системы сигнализации и иммобилизации
                            транспортного средства.
                          </li>
                          <li className="doc_list_elem">
                            Помощь в дозаправке Транспортного средства топливом
                            (кроме газового топлива) в количестве не более
                            десяти литров, включающее в себя не менее чем один
                            из видов следующих работ, при условии оплаты
                            Клиентом стоимости топлива, используемого в данном
                            Транспортном средстве, и при условии фактического
                            отсутствия топлива в топливном баке:
                            <ol className="doc_list">
                              <li className="doc_list_elem">
                                Непосредственная заправка топливом
                                соответствующего вида, с соблюдением условий
                                данной процедуры, определенных в требованиях по
                                эксплуатации и обслуживанию данного
                                Транспортного средства;
                              </li>
                              <li className="doc_list_elem">
                                Обеспечение доставки Клиента вместе или отдельно
                                от транспортного средства до места ближайшей
                                автомобильной заправочной станции
                                соответствующего вида топлива (и обратно, в
                                случае доставки Клиента отдельно от
                                Транспортного средства) и помощь в заправке
                                Транспортного средства указанным топливом.
                              </li>
                              <li className="doc_list_elem">
                                Работы по замене колеса Транспортного средства,
                                включающие в себя непосредственно замену колеса
                                Транспортного средства в сборе на штатное
                                запасное колесо Транспортного средства при
                                отсутствии сорванных/неисправных крепежных
                                болтов/гаек или иных систем и механизмов
                                крепления колеса, при наличии физической
                                возможности путем применения штатного
                                инструмента Транспортного средства, и при
                                условии отсутствия специальных устройств,
                                исключающих снятие колеса Транспортного средства
                                без применения других специальных устройств (в
                                случае отсутствия последних у Клиента); Если
                                Клиент настаивает на применении иных, не
                                предусмотренных штатной комплектацией
                                транспортного средства устройств для проведения
                                работ по замене колеса в рамках наполнения
                                карты, в заказе-наряде на оказание услуги
                                делается соответствующая отметка.
                              </li>
                              <li className="doc_list_elem">
                                Проведение работ по вскрытию дверей
                                Транспортного средства, багажника Транспортного
                                средства, капота Транспортного средства, а также
                                по вскрытию механических противоугонных систем,
                                установленных на данном Транспортном средстве,
                                при условии, что конструктивные особенности
                                Транспортного средства позволяют осуществить
                                данные работы.
                              </li>
                            </ol>
                          </li>
                        </ol>
                      </li>
                      <li className="doc_list_elem">
                        В рамках эвакуации транспортных средств, не менее чем
                        один из следующих видов работ и услуг:
                        <ol className="doc_list">
                          <li className="doc_list_elem">
                            Помещение полностью или частично Транспортного
                            средства на Эвакуатор, закрепление Транспортного
                            средства на Эвакуаторе, либо закрепление
                            буксировочного троса на Транспортном средстве в
                            целях буксировки в соответствии с требованиями
                            действующего законодательства, инструкции по
                            эксплуатации данного Транспортного средства и
                            требованиями техники безопасности, либо закрепление
                            устройства, обеспечивающего жесткую сцепку между
                            Эвакуатором и Транспортным средством в целях
                            буксировки в соответствии с требованиями
                            действующего законодательства, инструкции по
                            эксплуатации данных транспортных средств и
                            требованиями техники безопасности;
                          </li>
                          <li className="doc_list_elem">
                            {" "}
                            Перемещение Транспортного средства посредством
                            Эвакуатора, указанными выше методами, в следующее
                            место:
                            <ol className="doc_list">
                              <li className="doc_list_elem">
                                При нахождении Транспортного средства в момент
                                Неисправности на Автомобильной дороге в пределах
                                Московской кольцевой автомобильной дороги (МКАД)
                                и пятидесяти километров от МКАД, перемещение
                                Транспортного средства Клиента осуществляется на
                                одну из авторизированных станций технического
                                обслуживания (СТО) г. Москвы, перечисленных на
                                сайте Компании в разделе Контакты по выбору
                                Клиента.
                              </li>
                              <li className="doc_list_elem">
                                При нахождении Транспортного средства в момент
                                Неисправности на Автомобильной дороге в пределах
                                административных границ города, в котором
                                имеются авторизированные станции технического
                                обслуживания (СТО), и/или тридцати километрах от
                                административных границ данных городов,
                                перемещение Транспортного средства Клиента
                                осуществляется на авторизированную станцию
                                технического обслуживания (СТО) из списка,
                                указанного на сайте Компании в разделе Контакты,
                                в соответствующем городе.
                              </li>
                              <li className="doc_list_elem">
                                В нерабочее время авторизированных СТО или при
                                нахождении Транспортного средства в момент
                                Неисправности на Автомобильной дороге в границах
                                территории, указанной в Разделе 3 настоящих
                                Правил, за исключением территории в пределах
                                Московской кольцевой автомобильной дороги и
                                пятидесяти километров от МКАД, а также
                                административных границ городов, в которых есть
                                авторизированные станции технического
                                обслуживания (СТО), указанные на сайте Компании
                                и тридцати километров от административных границ
                                указанных городов, перемещение Транспортного
                                средства Клиента осуществляется в место,
                                указанное Клиентом, в границах территории,
                                указанной в Разделе 3 настоящих Правил, с учетом
                                требований настоящих Правил, действующего
                                законодательства и инструкций по эксплуатации
                                Транспортного средства и Эвакуатора.{" "}
                              </li>
                            </ol>
                          </li>
                          <li className="doc_list_elem">
                            Компания не несет ответственности за невозможность
                            принятия эвакуируемого Транспортного средства
                            Клиента станцией технического обслуживания, куда
                            Транспортное средство было доставлено при эвакуации.
                            В таких случаях услуга по эвакуации Транспортного
                            средства считается надлежащим образом выполненной
                            Компанией с момента доставки Транспортного средства
                            на СТО.
                          </li>
                          <li>
                            <div className="doc_list_elem">
                              При отказе или невозможности станцией технического
                              обслуживания принять Транспортное средство
                              Клиента, эвакуация Транспортного средства
                              (переадресация вызова) осуществляется в место,
                              указанное Клиентом, и оплачивается Клиентом в
                              соответствии с прейскурантом Компании, либо
                              Партнеру Компании по тарифам Партнера Компании на
                              месте оказания услуги до начала её оказания.{" "}
                            </div>
                            <div className="doc_list_elem">
                              Перемещение Транспортного средства с Эвакуатора на
                              землю и/или поверхность искусственного сооружения,
                              либо отцепление буксировочного троса, либо
                              устройства, обеспечивающего жесткую сцепку между
                              Транспортным средством и эвакуатором в целях
                              оставления Транспортного средства в месте,
                              указанном Клиентом, в границах территории,
                              указанной в Разделе 3 настоящих Правил, с учетом
                              положений пунктов 1.4.2.2 - 1.4.2.5 настоящих
                              Правил, действующего законодательства и инструкций
                              по эксплуатации Транспортного средства и
                              эвакуатора;
                            </div>
                            <div className="doc_list_elem">
                              Проведение работ по извлечению Транспортного
                              средства из труднодоступных мест в случае наличия
                              у Компании либо у партнера Компании
                              соответствующей технической возможности
                            </div>
                          </li>
                        </ol>
                      </li>
                      <li className="doc_list_elem">
                        В рамках справочно-консультационной помощи не менее чем
                        один из следующих видов работ и услуг:
                        <ol className="doc_list">
                          <li className="doc_list_elem">
                            Предоставление силами операторской службы Компании
                            справочной информации, имеющейся в базе данных
                            Компании;
                          </li>
                          <li className="doc_list_elem">
                            Осуществление юридических консультаций по телефону
                            по вопросам:
                            <ol className="doc_list">
                              <li className="doc_list_elem">
                                приобретения, таможенного оформления,
                                налогообложения, совершения регистрационных
                                действий, эксплуатации, обслуживания и ремонта
                                транспортных средств;
                              </li>
                              <li className="doc_list_elem">
                                в области защиты прав потребителей при
                                приобретении, эксплуатации, обслуживании и
                                ремонте транспортных средств;
                              </li>
                              <li className="doc_list_elem">
                                приобретения, отчуждения, мены, залога, дарения
                                и совершения иных гражданско-правовых сделок с
                                транспортными средствами;
                              </li>
                              <li className="doc_list_elem">
                                содержания, обустройства и эксплуатации
                                автомобильных стоянок и гаражей любого типа,
                                автосервисов, деятельности эксплуатационных
                                служб, а также иных правоотношений в области
                                автомобильного транспорта;{" "}
                              </li>
                              <li className="doc_list_elem">
                                по иным вопросам правоотношений в области
                                автомобильного транспорта и эксплуатации
                                транспортных средств, в том числе по вопросам
                                взаимоотношений с правоохранительными органами и
                                иными организациями в сфере автомобильного
                                транспорта и эксплуатации транспортных средств.
                              </li>
                            </ol>
                          </li>
                        </ol>
                      </li>
                    </ol>
                  </li>
                  <li className="doc_list_elem">
                    Услуги помощи на дороге выполняются по требованию Клиента
                    при наличии технической и физической возможности их
                    выполнения, при условии отсутствия у Компании стационарного
                    сервисного оборудования, и не более одного раза по каждому
                    случаю, послужившему причиной обращения в Компанию за
                    получением услуг.
                  </li>
                  <li className="doc_list_elem">
                    Любые работы, выполняемые по требованию Клиента в рамках
                    настоящей программы оказания услуг помощи на дороге,
                    выполняются только в его присутствии и только при
                    предъявлении Клиентом Карты Клиента, водительского
                    удостоверения, свидетельства о регистрации Транспортного
                    средства (паспорта Транспортного средства или технического
                    паспорта Транспортного средства) и документов,
                    подтверждающих законность владения данным Транспортным
                    средством.
                  </li>
                  <li className="doc_list_elem">
                    Компания вправе отказать полностью или частично в выполнении
                    любых работ, если такое выполнение затруднено или невозможно
                    в силу ограничения доступа к Транспортному средству
                    сотрудников Компании и/или партнера Компании, либо если
                    выполнение таких работ связано с возникновением опасности
                    причинения вреда.
                  </li>
                  <li className="doc_list_elem">
                    Компания не несет ответственности, за причинение Клиенту
                    морального вреда, упущенной выгоды, простоя, потери дохода и
                    других косвенных и коммерческих потерь, убытков и расходов
                    как Клиентов, так и третьих лиц, в том числе, но не
                    ограничиваясь: штрафы, проживание в гостинице во время
                    ремонта транспортного средства, командировочные расходы,
                    потери, связанные со сроками поставки товаров и производства
                    услуг и тому подобное если эти последствия не вызваны
                    виновными действиями Компании.
                  </li>
                  <li className="doc_list_elem">
                    В соответствии с настоящими Правилами Компания не несет
                    ответственности и вправе отказать в выполнении работ и/или
                    предоставлении услуг, предусмотренных пунктом 1.1 настоящих
                    Правил в случаях:
                    <ol className="doc_list">
                      <li className="doc_list_elem">
                        Умышленных действий Клиента, пассажиров Транспортного
                        средства, направленных на причинение Неисправности
                        Транспортному средству, либо при совершении или попытке
                        совершения указанными лицами преступления или иного
                        противоправного деяния; При изъятии в установленном
                        порядке водительского удостоверения – временного
                        разрешения на право управления транспортными средствами
                        и копии протокола об административном правонарушении,
                        выданных в установленном порядке.
                      </li>
                      <li className="doc_list_elem">
                        При обращении Клиента:
                        <ol className="doc_list">
                          <li className="doc_list_elem">
                            не допущенного к управлению данным Транспортным
                            средством;{" "}
                          </li>
                          <li className="doc_list_elem">
                            не имеющего права управления транспортными
                            средствами соответствующей категории;
                          </li>
                          <li className="doc_list_elem">
                            не имеющего документов, подтверждающих право
                            владения, пользования и/или распоряжения данным
                            Транспортным средством;
                          </li>
                          <li className="doc_list_elem">
                            находящегося в состоянии любой формы алкогольного,
                            наркотического или токсического опьянения или под
                            воздействием медикаментозных препаратов, применение
                            которых противопоказано при управлении транспортными
                            средствами, а также, если Клиент отказался пройти
                            медицинское освидетельствование (экспертизу);{" "}
                          </li>
                        </ol>
                      </li>
                      <li className="doc_list_elem">
                        противоправных действий Клиента, третьих лиц в отношении
                        работника Компании и/или партнера Компании, а равно при
                        законных требованиях уполномоченных должностных и/или
                        иных лиц о прекращении выполнения работ или об отказе от
                        их выполнения;
                      </li>
                      <li className="doc_list_elem">
                        при нахождении Транспортного средства в момент
                        возникновения события, послужившего причиной обращения в
                        Компанию вне территории, предусмотренной пунктом 1.2
                        настоящих Правил;
                      </li>
                      <li className="doc_list_elem">
                        обращения Клиента вне срока действия Карты Клиента;
                      </li>
                      <li className="doc_list_elem">
                        использования Транспортного средства, указанного в
                        учетной информации, в соревнованиях, испытаниях или для
                        обучения вождению;
                      </li>
                      <li className="doc_list_elem">
                        нарушения Клиентом или иным лицом правил эксплуатации
                        Транспортного средства, указанного в учетной информации,
                        в том числе использование технически неисправного
                        Транспортного средств, нарушения правил пожарной
                        безопасности, правил перевозки и хранения огнеопасных и
                        взрывоопасных и иных опасных веществ, и/или тяжеловесных
                        грузов и предметов, требований безопасности при
                        перевозке грузов;{" "}
                      </li>
                      <li className="doc_list_elem">
                        воздействия ядерного взрыва, радиации или радиоактивного
                        заражения;
                      </li>
                      <li className="doc_list_elem">
                        военных действий, маневров или иных военных мероприятий,
                        гражданской войны, народных волнений всякого рода или
                        забастовок, конфискации, изъятия, реквизиции, ареста;
                      </li>
                      <li className="doc_list_elem">
                        не сообщения Клиентом в установленном настоящими
                        Правилами порядке сведений об изменении учетных данных,
                        сообщенных Клиентом при приобретении Сертификата;{" "}
                      </li>
                      <li className="doc_list_elem">
                        в случае злоупотребления услугами Компании со стороны
                        Клиента.
                      </li>
                    </ol>
                  </li>
                  <li className="doc_list_elem">
                    Решение об объеме, виде выполняемых в рамках программы
                    помощи на дорогах работ и услуг, а также об ограничении
                    исполнения или отказе от исполнения полностью или частично
                    принимается уполномоченным сотрудником Компании и/или
                    партнера в зависимости от имеющихся условий, наличия
                    возможности и с учетом волеизъявления Клиента, в
                    соответствии с действующим законодательством, и настоящими
                    Правилами. В любом случае Компания оставляет за собой право
                    отказать полностью или частично в оказании услуг при наличии
                    подозрений о мошеннических или иных противоправных действий
                    Клиента и/или третьих лиц по отношению к Компании и/или
                    третьим лицам.
                  </li>
                  <li className="doc_list_elem">
                    Компания несет ответственность за вред, причиненный
                    Транспортному средству Клиента, в отношении которого
                    осуществляется фактическое оказание услуг, предусмотренных
                    пунктами 1.4.1 и 1.4.2 настоящих Правил, если такой вред
                    причинен в результате виновных действий Компании и/или
                    Партнера Компании.
                  </li>
                </ol>
              </li>
              <li className="doc_list_elem">
                ПРАВА И ОБЯЗАННОСТИ КЛИЕНТА И КОМПАНИИ.
                <ol className="doc_list">
                  <li className="doc_list_elem">
                    Клиент имеет право:
                    <ol className="doc_list">
                      <li className="doc_list_elem">
                        Требовать и заказывать оказание услуг, выполнение работ
                        Компанией, получать справочно-консультационную
                        информацию в соответствии с порядком, предусмотренным
                        разделом настоящих Правил.
                      </li>
                      <li className="doc_list_elem">
                        Сообщать Компании обо всех недостатках работ, услуг,
                        справочно-консультационной информации сотрудникам
                        Компании и/или партнерам Компании.
                      </li>
                    </ol>
                  </li>
                  <li className="doc_list_elem">
                    Клиент обязан:
                    <ol className="doc_list">
                      <li className="doc_list_elem">
                        Лично заказывать, приобретать и пользоваться услугами
                        Компании и предоставленной Компанией - консультационной
                        информацией, их плодами и результатами.
                      </li>
                      <li className="doc_list_elem">
                        Обеспечить достоверность учетной информации, сообщаемой
                        сотруднику Компании и/или партнера при оказании услуги.
                      </li>
                      <li className="doc_list_elem">
                        При заказе услуги и/или выполнении работы сообщать
                        сотруднику Компании и/или партнера полную и достоверную
                        информацию о характеристиках заказываемых услуг или
                        работ, а также о необходимом месте их получения. Заказ
                        услуги по программе оказания услуг помощи на дороге
                        осуществляется путем обращения по телефону
                      </li>
                      <li className="doc_list_elem">
                        Перед началом выполнения работ и/или оказания услуг,
                        предусмотренных настоящими Правилами, предъявить
                        сотруднику Компании и/или партнера по первому
                        требованию, водительское удостоверение (временное
                        разрешение и копию протокола об административном
                        правонарушении в случае изъятия водительского
                        удостоверения в установленном порядке) соответствующей
                        категории, свидетельство о регистрации данного
                        Транспортного средства, документы, подтверждающие право
                        владения, пользования или распоряжения данным
                        Транспортным средством.
                      </li>
                      <li className="doc_list_elem">
                        Своевременно проверять объем и качество выполненных
                        работ, оказанных услуг и принимать выполненные работы,
                        оказанные услуги в порядке, предусмотренном настоящими
                        Правилами.
                      </li>
                      <li className="doc_list_elem">
                        После окончания выполнения работ подписать все
                        документы, оформляемые сотрудником Компании и/или
                        партнера. При несогласии с данными, содержащимися в
                        документах, оформляемых по окончании работ, или
                        неудовлетворенности качеством услуг немедленно сообщить
                        об этом в Компанию по телефону, и/или письменно изложить
                        свои возражения в оформляемых документах.
                      </li>
                      <li className="doc_list_elem">
                        Выполнять все рекомендации, данные представителями
                        Компании, а также партнерами Компании, касающиеся
                        оказываемых Компанией услуг. 5 При наличии признаков
                        нахождения Клиента в состоянии опьянения или
                        одурманивания, выполнение любых работ и/или выполнение
                        услуг приостанавливается до документального
                        подтверждения факта отсутствия признаков употребления
                        наркотических, одурманивающих или вызывающих опьянение
                        веществ, либо до момента прекращения действия указанных
                        веществ.
                      </li>
                      <li className="doc_list_elem">
                        При утрате Карты по любой причине, сообщить в любой
                        доступной форме Заказчику незамедлительно, но не позднее
                        одних суток с момента обнаружения утраты. Восстановление
                        карты в этом случае производится Заказчиком.
                      </li>
                      <li className="doc_list_elem">
                        Содержать Транспортное средство, указанное в учетной
                        информации, в исправном состоянии, принимать меры
                        предосторожности в целях предотвращения причинения
                        ущерба транспортному средству.{" "}
                      </li>
                      <li className="doc_list_elem">
                        Выполнять условия настоящих Правил.{" "}
                      </li>
                    </ol>
                  </li>
                  <li className="doc_list_elem">
                    {" "}
                    Компания имеет право:
                    <ol className="doc_list">
                      <li className="doc_list_elem">
                        Оказывать услуги и выполнять работы, предусмотренные
                        программой, как своими силами, так и силами и за счет
                        партнеров Компании.
                      </li>
                      <li className="doc_list_elem">
                        Проверять представленную Клиентом информацию, а также
                        выполнение Клиентом условий настоящих Правил.
                      </li>
                      <li className="doc_list_elem">
                        Проводить осмотр и обследование поврежденного
                        Транспортного средства.{" "}
                      </li>
                      <li className="doc_list_elem">
                        Отказать в предоставлении Клиенту услуг и выполнении
                        работ в соответствии с пунктом 1.8 настоящих Правил.
                      </li>
                      <li className="doc_list_elem">
                        На досрочное прекращение оказания услуг в порядке,
                        установленном законодательством Российской Федерации, в
                        случае нарушения Клиентом, условий настоящих Правил
                      </li>
                      <li className="doc_list_elem">
                        Отказать полностью или частично Клиенту в предоставлении
                        услуг, справочно-консультационной информации, в случаях:
                        <ol className="doc_list">
                          <li className="doc_list_elem">
                            Несоответствия учетных данных, сообщенных
                            Заказчиком, соответствующим учетным данным,
                            сообщенным и/или предоставленным Клиентом при
                            заказе, приобретении и/или получении услуг и/или
                            работ Компании, а равно при несоответствии
                            действительности информации, предоставленной
                            Компании и/или партнеру Компании при заказе услуг
                            и/или работ Компании о желаемых характеристиках
                            таких услуг и/или работ, либо об объеме, месте и
                            времени получения услуг и/или работ.
                          </li>
                          <li className="doc_list_elem">
                            Заказа Клиентом выполнения каких-либо действий в
                            отношении иного транспортного средства (с иными
                            опознавательными регистрационными знаками,
                            идентификационными номерами и тому подобными
                            квалифицирующими признаками), чем указано в учетных
                            данных, сообщенных Заказчиком при Активации
                            Сертификата.
                          </li>
                          <li className="doc_list_elem">
                            Нарушения Клиентом условий настоящих Правил.
                          </li>
                          <li className="doc_list_elem">
                            Если необходимость их заказа или приобретения
                            вызвана противоправными интересами Клиента, а равно
                            в случаях, когда их выполнение непосредственно и/или
                            фактически связано с необходимостью нарушения
                            Компанией требований действующего законодательства,
                            принятых в обществе норм нравственности и морали.
                          </li>
                        </ol>
                      </li>
                      <li className="doc_list_elem">
                        При обращении Клиента в Компанию за услугами получить
                        согласие обратившегося Клиента на обработку его
                        персональных данных Компанией. В случае отсутствия
                        такого согласия Компания вправе отказать в оказании
                        услуг.
                      </li>
                    </ol>
                  </li>
                  <li className="doc_list_elem">
                    Компания обязана:
                    <ol className="doc_list">
                      <li className="doc_list_elem">
                        Обеспечить предоставление услуг в объемах, порядке и с
                        качеством, соответствующим требованиям настоящих Правил,
                        действующего законодательства и требованиям,
                        предъявляемым к подобного рода услугам в условиях
                        обычного делового оборота.{" "}
                      </li>
                      <li className="doc_list_elem">
                        Оказать иные услуги, на основании и в порядке,
                        предусмотренном настоящими Правилами и действующим
                        законодательством.
                      </li>
                      <li className="doc_list_elem">
                        Сообщить Клиенту перечень документов, необходимых к
                        представлению для обращений в Компанию в тех или иных
                        ситуациях.
                      </li>
                      <li className="doc_list_elem">
                        Не разглашать сведений о Клиенте, за исключением
                        случаев, прямо предусмотренных законодательством
                        Российской Федерации. Компания оставляет за собой право
                        сообщать сведения о Клиенте своим Партнерам на условиях,
                        в объеме и порядке, предусмотренном Договором,
                        заключенным с партнером, но только в случае, если
                        сообщение этих сведений необходимо для выполнения
                        обязательств, предусмотренных Договором в интересах
                        Клиента.
                      </li>
                      <li className="doc_list_elem">
                        Совершать иные действия, предусмотренные настоящими
                        Правилами и законодательством Российской Федерации.
                      </li>
                    </ol>
                  </li>
                  <li className="doc_list_elem">
                    В случае необходимости оказания Услуг в отношении
                    Транспортного средства за пределами территории, указанной в
                    пункте 1.2 настоящих Правил, Услуги Компании могут быть
                    оказаны (вне рамок Договора между Клиентом и Компанией и
                    настоящих Правил) только при условии оплаты Клиентом Услуг
                    Компании в момент оказания услуги наличными денежными
                    средствами, исходя из прейскуранта Компании, действующего на
                    момент обращения. При оказании услуг Клиенту Партнером
                    Компании услуги оплачиваются в кассу Партнера по ценам
                    Партнера за пробег Эвакуатора от места нахождения
                    Транспортного средства Клиента до начала территории,
                    указанной в пункте 1.2 настоящих Правил.{" "}
                  </li>
                  <li className="doc_list_elem">
                    В случае заказа Клиентом услуг/выполнения работ на
                    Автомобильной дороге, проезд по которой является платным,
                    Клиент обязан на месте оказания услуг компенсировать расходы
                    Компании/Партнера Компании, понесенные за проезд по такой
                    платной Автомобильной дороге.
                  </li>
                  <li className="doc_list_elem">
                    Стороны договорились, что услуги Компании оказываются только
                    с тем Транспортным средством, которое указано в учетных
                    данных.
                  </li>
                  <li className="doc_list_elem">
                    Стороны договорились, что Компания вправе в одностороннем
                    порядке прекратить раньше срока окончания действия
                    обязательств Компании оказание услуг в отношении
                    Транспортного средства, в случае документально доказанного
                    трехкратного злоупотребления услугами Компании со стороны
                    Клиента.
                  </li>
                  <li className="doc_list_elem">
                    Стороны договорились считать злоупотреблением услугами
                    Компании со стороны Клиента следующие действия Клиента:
                    <ul>
                      <li className="doc_list_elem">
                        Невыполнение рекомендации Компании, данных Клиенту при
                        оказании услуг на условиях и в порядке, предусмотренных
                        настоящими Правилами;
                      </li>
                      <li className="doc_list_elem">
                        Заказ услуг по транспортировке Транспортного средства
                        или экстренной технической помощи в случаях, не
                        предусмотренных настоящими Правилами;
                      </li>
                      <li className="doc_list_elem">
                        Умышленный заказ услуг по технической помощи без
                        необходимости, а равно умышленный обман Компании при
                        указании необходимости в выполнении Компанией работ,
                        оказании услуг на условиях и в порядке, предусмотренных
                        настоящими Правилами;
                      </li>
                      <li className="doc_list_elem">
                        Любые умышленные действия Клиента, направленные на
                        выполнение Компанией работ, в порядке ином, чем
                        установлено настоящими Правилами.
                      </li>
                    </ul>
                  </li>
                </ol>
              </li>
            </ol>
          </div>
        </Block>
      </div>
    </div>
  );
}
