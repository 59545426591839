
const faq = [
	{
		label: 'Что такое гарантия на автомобиль с пробегом?',
		content: 'Наша гарантия – это обязательство починить ваш автомобиль в случае его поломки. При наступлении гарантийного случая мы производим ремонт вашей машины и обеспечиваем замену необходимых деталей.',
	},
	{
		label: 'Чем ваша гарантия отличается от гарантии завода-изготовителя?',
		content: 'В общих чертах наша гарантия очень похожа на гарантию изготовителя для нового за исключением того, что вы можете оформить нашу гарантию в любой момент и практически на любой автомобиль. Также возможны отличия в узлах автомобиля, покрываемых гарантией в зависимости от выбранной вами гарантийной программы.',
	},	
	{
		label: 'Какие детали и работы покрываются гарантией в случае поломки?',
		content: 'Список компонентов автомобиля и работа по их ремонту зависит от выбранной вами гарантийной программы. Как правило, покрываются основные (то есть самые дорогостоящие) узлы автомобиля.',
	},
	// {
	// 	label: 'Где можно отремонтировать свой автомобиль при наступлении гарантийного случая?',
	// 	content: `Вы можете выбрать одну из наших станций технического обслуживания согласно списку. Мы постоянно расширяем сеть наших партнеров по ремонту автомобилей, поэтому самый полный вариант доступен на нашем сайте.`,
	// },
	// {
	// 	label: 'На каких сервисных станциях машина должна проходить регулярное техническое обслуживание?',
	// 	content: 'Вы можете выбрать одну из наших станций технического обслуживания согласно списку. Мы постоянно расширяем сеть наших партнеров по ремонту автомобилей, поэтому самый полный вариант доступен на нашем сайте.'
	// },
    {
		label: 'Возможна ли передача гарантии другому лицу?',
		content: 'Да. Гарантия привязана только к конкретному автомобилю и может быть передана любому лицу.'
	},
    {
		label: 'Можно ли получить деньги при наступлении гарантийного случая?',
		content: 'Нет, мы обязуемся произвести ремонт вашего автомобиля, а расчеты за работы и услуги проводятся между нами и сервисными компаниями напрямую.'
	},
    {
		label: 'Какие требования существуют по обслуживанию автомобиля с вашей гарантией?',
		content: 'Мы не выдвигаем особых требований по обслуживанию. Вы проходите техническое обслуживание согласно указаниям производителя, делаете отметки в нашей сервисной книжке и сохраняете документы, подтверждающие, что работы были проведены в требуемый срок.'
	},
    {
		label: 'Что мне делать в случае поломки?',
		content: `В первую очередь, рекомендуем вам прекратить эксплуатацию автомобиля в целях вашей собственной безопасности и во избежание дополнительных повреждений.
        Далее, в течение 48 часов вам следует обратиться в наш колл-центр по телефону, указанному в вашем гарантийном сертификате. Наши специалисты предложат вам дальнейший порядок действий и согласуют процедуру гарантийного ремонта.`
	},
    {
		label: 'Сколько времени занимает согласование ремонта?',
		content: 'В большинстве случаев согласование производится в течение рабочего дня.'
	},

];
export default faq;