import React from 'react';

import rolf from '../assets/img/partners/rolf.png'
import automir from '../assets/img/partners/automir.png'
import autorus from '../assets/img/partners/autorus.jpeg'
import Inchape from '../assets/img/partners/Inchcape.png'
import major from '../assets/img/partners/major.jpeg'
import petr from '../assets/img/partners/petr.jpeg';

const partners = [
  {
    description: (
      <>
        <p>
          <span>РОЛЬФ</span> - крупнейшая дилерская группа, импортер и продавец
          автомобилей иностранных марок. Розничная сеть РОЛЬФ насчитывает 35
          шоу-румов в Москве и Санкт-Петербурге и представляет 15 автомобильных
          брендов, среди которых - как массовые, так и премиальные марки. 22
          года успешной работы на рынке.
        </p>

        <p>
          Скидка во всех сервисцентрах дилерской группы РОЛЬФ для владельцев
          сертификатов "РосГарантСервис" - 10% на все виды работ и запасные
          части.
        </p>
      </>
    ),
    adress: <>РОЛЬФ Витебский </>,
    logo: rolf,
  },
  {
    description: (
      <>
        <p>
          <span>АВТОМИР</span> - крупнейшая дилерская группа, импортер и
          продавец автомобилей иностранных марок. Розничная сеть РОЛЬФ
          насчитывает 35 шоу-румов в Москве и Санкт-Петербурге и представляет 15
          автомобильных брендов, среди которых - как массовые, так и премиальные
          марки. 22 года успешной работы на рынке.{' '}
        </p>
        <p>
          Скидка во всех сервисцентрах дилерской группы АВТОМИР для владельцев
          сертификатов "РосГарантСервис" - 10% на все виды работ и запасные
          части.
        </p>
      </>
    ),
    adress: <>АВТОМИР</>,
    logo: automir,
  },
  {
    description: (
      <>
        <p>
          <span>МАЖОР</span> - крупнейшая дилерская группа, импортер и продавец
          автомобилей иностранных марок. Розничная сеть РОЛЬФ насчитывает 35
          шоу-румов в Москве и Санкт-Петербурге и представляет 15 автомобильных
          брендов, среди которых - как массовые, так и премиальные марки. 22
          года успешной работы на рынке.{' '}
        </p>
        <p>
          Скидка во всех сервисцентрах дилерской группы МАЖОР для владельцев
          сертификатов "РосГарантСервис" - 10% на все виды работ и запасные
          части.
        </p>
      </>
    ),
    adress: <>Major Auto</>,
    logo: major,
  },
  {
    description: (
      <>
        <p>
          <span>ГЕРМЕС</span> - крупнейшая дилерская группа, импортер и продавец
          автомобилей иностранных марок. Розничная сеть АВТОМИР насчитывает 35
          шоу-румов в Москве и Санкт-Петербурге и представляет 15 автомобильных
          брендов, среди которых - как массовые, так и премиальные марки. 22
          года успешной работы на рынке.{' '}
        </p>
        <p>
          Скидка во всех сервисцентрах дилерской группы ГЕРМЕС для владельцев
          сертификатов "РосГарантСервис" - 10% на все виды работ и запасные
          части.
        </p>
      </>
    ),
    adress: <>ГЕРМЕС</>,
  },
  {
    description: (
      <>
        <p>
          <span>Inchcape</span> - крупнейшая дилерская группа, импортер и
          продавец автомобилей иностранных марок. Розничная сеть Inchcape
          насчитывает 35 шоу-румов в Москве и Санкт-Петербурге и представляет 15
          автомобильных брендов, среди которых - как массовые, так и премиальные
          марки. 22 года успешной работы на рынке.{' '}
        </p>
        <p>
          Скидка во всех сервисцентрах дилерской группы Inchcape для владельцев
          сертификатов "РосГарантСервис" - 10% на все виды работ и запасные
          части.
        </p>
      </>
    ),
    adress: <>Inchcape</>,
    logo: Inchape,
  },
  {
    description: (
      <>
        <p>
          <span>Русь</span> - крупнейшая дилерская группа, импортер и продавец
          автомобилей иностранных марок. Розничная сеть Русь насчитывает 35
          шоу-румов в Москве и Санкт-Петербурге и представляет 15 автомобильных
          брендов, среди которых - как массовые, так и премиальные марки. 22
          года успешной работы на рынке.{' '}
        </p>
        <p>
          Скидка во всех сервисцентрах дилерской группы Русь для владельцев
          сертификатов "РосГарантСервис" - 10% на все виды работ и запасные
          части.
        </p>
      </>
    ),
    adress: <>Русь</>,
    logo: autorus,
  },
  {
    description: (
      <>
        <p>
          <span>ПЕТРОВСКИЙ</span> - крупнейшая дилерская группа, импортер и
          продавец автомобилей иностранных марок. Розничная сеть ПЕТРОВСКИЙ
          насчитывает 35 шоу-румов в Москве и Санкт-Петербурге и представляет 15
          автомобильных брендов, среди которых - как массовые, так и премиальные
          марки. 22 года успешной работы на рынке.{' '}
        </p>
        <p>
          Скидка во всех сервисцентрах дилерской группы ПЕТРОВСКИЙ для
          владельцев сертификатов "РосГарантСервис" - 10% на все виды работ и
          запасные части.
        </p>
      </>
    ),
    adress: <>ПЕТРОВСКИЙ</>,
    logo: petr,
  },
];

export default partners;
