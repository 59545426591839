const statistics = [
    {
        num: '7',
        text: 'лет в бизнесе'
    },
    {
        num: '999+',
        text: 'довольных клиентов'
    },
]

export default statistics;