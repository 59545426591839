import React from 'react'

export default function Button(props) {
    return (
        
        <a className="banner_button" href={props.href}>
            Получить бесплатную консультацию
        </a>
        
    )
}
